<template>

  <div class="container-sm section-default">
    <div class="row">
      <div class="col-6 d-flex align-items-center">
        <section class="py-5 text-left container">
          <h1 class="heading1 heading-site-21 text-start">Recrute os </h1>
          <h1 class="heading1 heading-site-21 text-start"><span class="green-text">melhores talentos</span></h1>
          <h1 class="heading1 heading-site-21 text-start">da tecnologia em </h1>
          <h1 class="heading1 heading-site-21 text-start">menos<span class="green-text"> tempo</span></h1>
          <p class="py-1 text-start">Ferramentas e suporte especializado no recrutamento e seleção de profissionais tech para você contratar com qualidade e rapidez </p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start ">
            <router-link to="/cadastro-empresas" class="home-button btn-primary-site-sfit-21">Encontre profissionais</router-link> 
            <!-- <router-link to="/cadastro-profissionais" class="home-button btn-primary-site-sfit-21">Criar perfil grátis</router-link> -->
          </div>
        </section>
      </div>
      
      <div class="col-6">
            <img src="/img/empresa.png" loading="lazy" width="552" alt="" class="responsive-hide">
      </div>
    </div>

      


  </div>
  
</template>

<script>

</script>

<style scoped>

.heading1 {
    margin-bottom: 10px;
    font-family: Antenna,sans-serif;
    font-size: 58px;
    line-height: 60px;
    font-weight: 400;
}
.green-text {
  color: #00da80;
}

.sub-title {
    font-size: 22px;
    margin: 10px 0px 10px;
}

.home-button {
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 40px;
  min-width: 150px;
  margin: 10px 10px 0px 0px;
  padding: 15px 30px;
  clear: left;
  border-radius: 4px;
  background-color: #5F78EA;
  font-family: Antenna, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  min-width: 258px;
}

.home-button:hover {
  background-color: #4D5FAF;
  color: #fff;
}

a {
    color: #3256a1;
    text-decoration: none;
}

</style>